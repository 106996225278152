<script>

import {
  required,
  email,
  // minLength
} from "vuelidate/lib/validators";
import Switches from "vue-switches";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { notificationMethods } from "@/state/helpers";
import {
  CREATE_SUB_USER,
  UPDATE_SUB_USER_DETAILS,
  FETCH_DETAILS_FOR_ALL_SUB_USERS,
} from "@/state/actions.type";
import Multiselect from 'vue-multiselect';
import * as cloneDeep from "lodash.clonedeep";
import {rolesPermissionMapping} from "../../../assets/rbac-roles";

export default {
  page: {
    title: "Account Settings",
  },

  components: {
    Switches,
    Layout,
    PageHeader,
    Multiselect,
  },

  data() {
    return {
      // TODO: Get this dynamically via API
      currentSubUserDetail: {
        name: "",
        phone_number: "",
        email_address: "",
        is_active: false,
        is_global_user: true,
        roles: [],
      },
      editSubUserDetail: {
        name: "",
        phone_number: "",
        email_address: "",
        is_active: false,
        is_global_user: true,
        roles: [],
      },
      addUserFormSubmitted: false,
      editUserFormSubmitted: false,
      showAddUserModal: false,
      showEditUserModal: false,
      fields: [
        { key: "name", sortable: true, tooltip: "accounts.table.tooltip.name", label: "accounts.table.fields.name" },
        { key: "email_address", sortable: true, tooltip: "accounts.table.tooltip.email_address", label: "accounts.table.fields.email_address" },
        { key: "phone_number", sortable: true, tooltip: "accounts.table.tooltip.phone_number", label: "accounts.table.fields.phone_number" },
        { key: "roles", sortable: false, tooltip: "accounts.table.tooltip.roles", label: "accounts.table.fields.roles" },
        { key: "is_active", sortable: true, tooltip: "accounts.table.tooltip.is_active", label: "accounts.table.fields.is_active" },
        { key: "is_2fa_enabled", sortable: true, tooltip: "accounts.table.tooltip.is_2fa_enabled", label: "accounts.table.fields.is_2fa_enabled" },
        { key: "options", sortable: false, tooltip: "accounts.table.tooltip.options", label: "accounts.table.fields.options" },
      ],
    };
  },

  created() {
    this.$store.dispatch('notification/clear');
    this.$store.dispatch(`accounts/${FETCH_DETAILS_FOR_ALL_SUB_USERS}`);
  },

  computed: {
    subUserDetails() {
      return this.$store.state.accounts.subUserData;
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    title() {
      return this.$t('views.accounts.accounts');
    },

    items() {
      return [{
        text: 'PortOne',
        active: true,
      },
      {
        text: this.$t('views.accounts.accounts'),
        active: true,
      }];
    },
    subUserUuid() {
      return this.$store.state.auth.currentUser.subUserUuid;
    },
    userRoles() {
      return this.$store.state.auth.currentUser.roles;
    },

    rolesList() {
      let roles = [
        'payment-user',
        'payment-admin',
        'payment-links-user',
        'payouts-admin',
        'payouts-user',
        'payouts-verify-user',
        'payouts-approve-user',
        'payout-channel-configurator',
        'payment-channel-configurator',
        'payment-refund-user',
        'payment-pages-user',
        'invoice-user',
        'recon-user',
        'recon-admin',
        'recon-configurator'
      ]
      if(this.userRoles.includes('admin')) {
        roles = [...roles, 'sub-admin'];
      }
      return roles;
    }
  },

  validations: {
    currentSubUserDetail: {
      name: { required },
      email_address: { required, email },
      phone_number: { required },
    },
    editSubUserDetail: {
      name: { required },
      email_address: { required, email },
      phone_number: { required },
    },
  },

  methods: {
    ...notificationMethods,

    // eslint-disable-next-line no-unused-vars
    addSubUser(e) {
      this.addUserFormSubmitted = true;

      // stop here if form is invalid
      this.$v.currentSubUserDetail.$touch();
      if (this.$v.currentSubUserDetail.$invalid) {
        return;
      } else {

        this.$store.dispatch(`accounts/${CREATE_SUB_USER}`, this.currentSubUserDetail)
        // eslint-disable-next-line no-unused-vars
          .then((result) => {
            // Added new user
            this.showAddUserModal = false;
          })
          .catch((error) => {
            // Added new user
            console.log(error);
            const errorObject = JSON.parse(error.message)
            this.$notify({
                type: "error",
                text: errorObject.message,
                closeOnClick: true,
            });
            this.showAddUserModal = false;
          });

        this.currentSubUserDetail = {
          name: "",
          phone_number: "",
          email_address: "",
          is_active: false,
          is_global_user: true,
          roles: [],
        };
      }
      this.addUserFormSubmitted = false;
    },

    // eslint-disable-next-line no-unused-vars
    updateSubUser(e) {
      this.editUserFormSubmitted = true;

      // stop here if form is invalid
      this.$v.editSubUserDetail.$touch();
      if (this.$v.editSubUserDetail.$invalid) {
        return;
      } else {

        this.$store.dispatch(`accounts/${UPDATE_SUB_USER_DETAILS}`, {subUserID: this.editSubUserDetail.uuid, subUserDetails: this.editSubUserDetail})
          // eslint-disable-next-line no-unused-vars
          .then((result) => {
            // Added new user
            this.$store.dispatch(`accounts/${FETCH_DETAILS_FOR_ALL_SUB_USERS}`)
            this.showEditUserModal = false;
          })
          .catch((error) => {
            // Added new user
            console.log(error);
            this.showEditUserModal = false;
            const errorObject = JSON.parse(error.message)
            this.$notify({
                type: "error",
                text: errorObject.message,
                closeOnClick: true,
            });
          });

        this.currentSubUserDetail = {
          name: "",
          phone_number: "",
          email_address: "",
          is_active: false,
          is_global_user: true,
          roles: [],
        };
      }
      this.editUserFormSubmitted = false;
    },

    openAddUserModal() {
      this.resetNotificationError();
      this.showAddUserModal = true;
    },

    // eslint-disable-next-line no-unused-vars
    hideAddUserModal(e) {
      this.currentSubUserDetail = {
        name: "",
        phone_number: "",
        email_address: "",
        is_active: false,
        is_global_user: true,
        roles: [],
      };
      this.addUserFormSubmitted = false;
      this.showAddUserModal = false;
    },

    openEditUserModal(userDetails) {
      if(this.editAccess(userDetails)) {
        this.editSubUserDetail = cloneDeep(userDetails);
        this.resetNotificationError();
        this.showEditUserModal = true;
      }
    },

    // eslint-disable-next-line no-unused-vars
    hideEditUserModal(e) {
      this.editSubUserDetail = {
        name: "",
        phone_number: "",
        email_address: "",
        is_active: false,
        is_global_user: true,
        roles: [],
      };
      this.editUserFormSubmitted = false;
      this.showEditUserModal = false;
    },

    resetNotificationError() {
      this.$store.dispatch('notification/clear');
    },

    toggleSwitchForEdit(val) {
      this.editSubUserDetail.is_active = val;
    },

    toggleSwitchForAdd(val) {
      this.currentSubUserDetail.is_active = val;
    },

    toggleSwitchForGlobalUser(val) {
      this.currentSubUserDetail.is_global_user = val;
    },
    // TODO: Get this dynamically via API
    userPermissionsByRole(role) {
      return rolesPermissionMapping[role];
    },

    toggleSwitchForEditGlobalUser(val) {
      this.editSubUserDetail.is_global_user = val;
    },
    editAccess(item) {
      if((item.uuid === this.subUserUuid) && (this.userRoles.includes('admin') || this.userRoles.includes('sub-admin'))) {
        return true;
      }

      if(this.userRoles.includes('admin') && !item.roles.includes('admin')) {
        return true;
      }

      if(this.userRoles.includes('sub-admin') && !item.roles.includes('admin') && !item.roles.includes('sub-admin')) {
        return true;
      }

      return false;
    }
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-alert
      variant="danger"
      class="mt-3"
      v-if="notification.message"
      show
      dismissible
      @dismissed="resetNotificationError"
    >{{notification.message}}</b-alert>

    <div class="row mt-4">
      <div class="col-lg-12">
        <div class="d-flex justify-content-end">
          <b-btn
            variant="primary"
            size="sm"
            @click="openAddUserModal"
          >
            {{ $t('views.accounts.invite_new_user') }}
          </b-btn>
        </div>

        <div class="table-responsive text-center table-custom mt-3">
          <b-table
            class="table-centered"
            :items="subUserDetails"
            :fields="fields"
            responsive="sm"
            :bordered="true"
            sticky-header="500px"
            show-empty
            :empty-text="$t('common.no-records')"
            busy.sync="true"
            hover
          >
            <template v-slot:head()="data">
              <span v-b-tooltip.hover.top :title='$t(data.field.tooltip)'>
                {{ $t(data.label) }}
              </span>
            </template>
            <template v-slot:cell(options)="row">
              <div class="badge font-size-12">
                <button class="btn btn-light btn-sm" style="padding: 0.3em 1.5em" v-if="editAccess(row.item)" @click="openEditUserModal(row.item)">{{ $t('button.edit') }}</button>
              </div>
            </template>

            <template v-slot:cell(is_active)="row">
              <div
                class="badge font-size-14"
                :class="{'badge-soft-danger': row.value == false, 'badge-soft-success': row.value == true}"
              >{{ row.value ? $t("accounts.active") : $t("accounts.inactive") }}</div>
            </template>

            <template v-slot:cell(is_2fa_enabled)="row">
              <div
                class="badge font-size-14"
                :class="{'badge-soft-danger': row.value == false, 'badge-soft-success': row.value == true}"
              >{{ row.value ? $t("accounts.enabled") : $t("accounts.not_enabled") }}</div>
            </template>

            <template v-slot:cell(roles)="row">
              <!-- <div
                v-for="role in row.value"
                :key="role"
                class="badge badge-secondary mr-1 font-size-14"
              >{{ role }}</div> -->
              <div
                v-for="role in row.value"
                :key="role"
                class="badge badge-secondary mr-1 font-size-14"
              >
                <span :id="role+row.item.email_address" >{{ role }}</span>
                <b-tooltip :target="role+row.item.email_address" triggers="hover" placement="right">
                  <span>{{ 'Permissions : [ '  + userPermissionsByRole(role) + ' ]' }}</span>
                </b-tooltip>
              </div>
            </template>

          </b-table>
        </div>
      </div>
    </div>

    <!-- Add New User Modal -->
    <b-modal
      size="lg"
      id="add-user-modal"
      v-model="showAddUserModal"
      :title="$t('accounts.add_user')"
      title-class="text-dark font-18"
      no-close-on-backdrop
      hide-footer
    >
      <form @submit.prevent="addSubUser">

        <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">{{ $t("accounts.table.fields.name") }}<span class="ml-1 text text-danger">*</span></label>
                <input
                  id="name"
                  v-model="currentSubUserDetail.name"
                  type="text"
                  class="form-control"
                  :placeholder="$t('accounts.placeholder.name')"
                  :class="{ 'is-invalid': addUserFormSubmitted && $v.currentSubUserDetail.name.$error }"
                />
                <div
                  v-if="addUserFormSubmitted && !$v.currentSubUserDetail.name.required"
                  class="invalid-feedback"
                >
                  {{ $t('accounts.required.name') }}
                </div>
            </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="email_address">{{ $t("accounts.table.fields.email_address") }}<span class="ml-1 text text-danger">*</span></label>
                <input
                  id="email_address"
                  v-model="currentSubUserDetail.email_address"
                  type="email"
                  class="form-control"
                  :placeholder="$t('accounts.placeholder.email')"
                  :class="{ 'is-invalid': addUserFormSubmitted && $v.currentSubUserDetail.email_address.$error }"
                />
                <div
                  v-if="addUserFormSubmitted && !$v.currentSubUserDetail.email_address.required"
                  class="invalid-feedback"
                >
                  {{ $t('accounts.required.email') }}
                </div>
            </div>
            </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="phone_number">{{ $t("accounts.table.fields.phone_number") }}<span class="ml-1 text text-danger">*</span></label>
              <input
                id="phone_number"
                v-model="currentSubUserDetail.phone_number"
                type="text"
                class="form-control"
                :placeholder="$t('accounts.placeholder.phone')"
                :class="{ 'is-invalid': addUserFormSubmitted && $v.currentSubUserDetail.phone_number.$error }"
              />
              <div
                v-if="addUserFormSubmitted && !$v.currentSubUserDetail.name.required"
                class="invalid-feedback"
              >
                {{ $t('accounts.required.phone') }}
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label for="is_active">{{ $t("accounts.activate_acc") }}<span class="ml-1 text text-danger">*</span></label>
              <!-- <input
                id="is_active"
                v-model="currentSubUserDetail.is_active"
                type="checkbox"
                class="form-control"
              /> -->
              <div>
                <switches
                  :value="currentSubUserDetail.is_active"
                  type-bold="true"
                  color="primary"
                  class="ml-1 mt-3"
                  size="sm"
                  @input="toggleSwitchForAdd($event)"
                ></switches>
              </div>

            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label for="is_global_user">{{ 'Global User' }}<span class="ml-1 text text-danger">*</span></label>
              <!-- <input
                id="is_active"
                v-model="currentSubUserDetail.is_active"
                type="checkbox"
                class="form-control"
              /> -->
              <div>
                <switches
                  :value="currentSubUserDetail.is_global_user"
                  type-bold="true"
                  color="primary"
                  class="ml-1 mt-3"
                  size="sm"
                  @input="toggleSwitchForGlobalUser($event)"
                ></switches>
              </div>

            </div>
          </div>
        </div>

        <div class="form-group">
          <label>{{ $t("accounts.table.fields.roles") }}<span class="ml-1 text text-danger">*</span></label>
          <multiselect
            v-model="currentSubUserDetail.roles"
            :options="rolesList"
            :placeholder="$t('accounts.placeholder.roles')"
            size="sm"
            :searchable="true"
            :multiple="true"
            :taggable="true"
          ></multiselect>
        </div>

        <div class="text-right">
          <b-button class="" variant="light" @click="hideAddUserModal">{{ $t('button.close') }}</b-button>
          <button type="submit" class="btn btn-primary ml-2">{{ $t('button.add') }}</button>
        </div>
      </form>
    </b-modal>

    <!-- Edit User Modal -->
    <b-modal
      size="lg"
      id="add-user-modal"
      v-model="showEditUserModal"
      :title="$t('accounts.edit_user_details')"
      title-class="text-dark font-18"
      no-close-on-backdrop
      hide-footer
    >
      <form @submit.prevent="updateSubUser">

        <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">{{ $t("accounts.table.fields.name") }}<span class="ml-1 text text-danger">*</span></label>
                <input
                  id="name"
                  v-model="editSubUserDetail.name"
                  type="text"
                  class="form-control"
                  :placeholder="$t('accounts.placeholder.name')"
                  :class="{ 'is-invalid': editUserFormSubmitted && $v.editSubUserDetail.name.$error }"
                />
                <div
                  v-if="editUserFormSubmitted && !$v.editSubUserDetail.name.required"
                  class="invalid-feedback"
                >
                  {{ $t("accounts.required.name") }}
                </div>
            </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="email_address">{{ $t("accounts.table.fields.email_address") }}</label>
                <input
                  readonly
                  id="email_address"
                  v-model="editSubUserDetail.email_address"
                  type="email"
                  :placeholder="$t('accounts.placeholder.email')"
                  class="form-control"
                  :class="{ 'is-invalid': editUserFormSubmitted && $v.editSubUserDetail.email_address.$error }"
                />
                <div
                  v-if="editUserFormSubmitted && !$v.editSubUserDetail.email_address.required"
                  class="invalid-feedback"
                >
                  {{ $t("accounts.required.email") }}
                </div>
            </div>
            </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="phone_number">{{ $t("accounts.table.fields.phone_number") }}<span class="ml-1 text text-danger">*</span></label>
              <input
                id="phone_number"
                v-model="editSubUserDetail.phone_number"
                type="text"
                class="form-control"
                :placeholder="$t('accounts.placeholder.phone')"
                :class="{ 'is-invalid': editUserFormSubmitted && $v.editSubUserDetail.phone_number.$error }"
              />
              <div
                v-if="editUserFormSubmitted && !$v.editSubUserDetail.name.required"
                class="invalid-feedback"
              >
                {{ $t("accounts.required.phone") }}
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group" style="display: grid;">
              <label for="is_active">{{ $t("accounts.activate_acc") }}<span class="ml-1 text text-danger">*</span></label>
              <!-- <input
                id="is_active"
                v-model="editSubUserDetail.is_active"
                type="checkbox"
                class="form-control"
              /> -->
              <switches
                :disabled="editSubUserDetail.roles.includes('admin') || (!userRoles.includes('admin') && editSubUserDetail.roles.includes('sub-admin'))"
                type-bold="true"
                :value="editSubUserDetail.is_active"
                color="primary"
                class="ml-1 mt-3 font-size-10"
                @input="toggleSwitchForEdit($event)"
              ></switches>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group" style="display: grid;">
              <label for="is_global_user">{{ 'Global User' }}<span class="ml-1 text text-danger">*</span></label>
              <!-- <input
                id="is_active"
                v-model="editSubUserDetail.is_active"
                type="checkbox"
                class="form-control"
              /> -->
              <switches
                :disabled="editSubUserDetail.roles.includes('admin') || (!userRoles.includes('admin') && editSubUserDetail.roles.includes('sub-admin'))"
                type-bold="true"
                :value="editSubUserDetail.is_global_user"
                color="primary"
                class="ml-1 mt-3 font-size-10"
                @input="toggleSwitchForEditGlobalUser($event)"
              ></switches>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>{{ $t("accounts.table.fields.roles") }}<span class="ml-1 text text-danger">*</span></label>
          <multiselect
            v-model="editSubUserDetail.roles"
            :options="rolesList"
            :disabled="editSubUserDetail.roles.includes('admin') || (!userRoles.includes('admin') && editSubUserDetail.roles.includes('sub-admin'))"
            placeholder="Set Roles"
            size="sm"
            :searchable="true"
            :multiple="true"
            :taggable="true"
          ></multiselect>
        </div>

        <div class="text-right">
          <b-button variant="light" @click="hideEditUserModal">{{ $t('button.close') }}</b-button>
          <button type="submit" class="btn btn-primary ml-2">{{ $t('button.update') }}</button>
        </div>
      </form>
    </b-modal>


  </Layout>
</template>
